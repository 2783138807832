<template>
  <div>
    <div>
      <p class="h4">
        Wallet Statistics
      </p>
    </div>

    <div class="card-deck mb-4">
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">100</p>
            <p class="lead text-muted">Total No. of users</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainColor rounded-circle">
              <ion-icon size="large" name="people"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">
              400,000
              <small class="h6">&#8358;</small>
            </p>
            <p class="lead text-muted">System Wallet</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainThree rounded-circle">
              <ion-icon size="large" name="card"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">50,00</p>
            <p class="lead text-muted">Gift card traded</p>
          </div>
          <div>
            <div class="p-2 bg-light mainColor rounded-circle">
              <ion-icon size="large" name="gift"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-deck mb-4">
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">100</p>
            <p class="lead text-muted">Total No. of users</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainColor rounded-circle">
              <ion-icon size="large" name="people"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">
              400,000
              <small class="h6">&#8358;</small>
            </p>
            <p class="lead text-muted">System Wallet</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainThree rounded-circle">
              <ion-icon size="large" name="card"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-deck mb-4">
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">100</p>
            <p class="lead text-muted">Total No. of users</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainColor rounded-circle">
              <ion-icon size="large" name="people"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">
              400,000
              <small class="h6">&#8358;</small>
            </p>
            <p class="lead text-muted">System Wallet</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainThree rounded-circle">
              <ion-icon size="large" name="card"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">
              10.003490
              <small class="h5"></small>
            </p>
            <p class="lead text-muted">balance</p>
          </div>
          <div>
            <div class="p-2 colorOneBg mainOne rounded-circle">
              <ion-icon size="large" name="logo-bitcoin"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow">
        <div class="card-body d-flex justify-content-between alig-items-ce">
          <div>
            <p class="h3">50,00</p>
            <p class="lead text-muted">Gift card traded</p>
          </div>
          <div>
            <div class="p-2 bg-light mainColor rounded-circle">
              <ion-icon size="large" name="gift"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletLog",
};
</script>

<style scoped></style>
